<template>
  <div class="search">
    <div class="flex flex-between align-center search-box">
      <div class="flex flex-left align-center search-box-l">
        <div class="flex flex-left align-center search-box-l-type">
          <van-popover v-model:show="showPopover" :actions="actions" placement="bottom-start" @select="onSelect">
            <template #reference>
              <van-button type="default" class="search-box-l-type-btn">{{searchType}}</van-button>
            </template>
          </van-popover>
          <i></i>
        </div>
        <div class="search-box-l-line"></div>
        <div class="search-box-l-input">
          <input type="text" v-model="searchName" :placeholder="placeholder">
        </div>
      </div>
      <div class="search-box-btn">
        <van-button type="default" class="search-box-btn-con" @click="searchFunc(0)">搜索</van-button>
      </div>
    </div>
    <div class="search-record">
      <div class="search-record-title">推荐搜索</div>
      <div class="flex flex-left flex-wrap search-record-list">
        <!-- searchFunc(1, item.name, item.id) -->
        <div class="flex flex-center align-center search-record-list-div" v-for="item in searchRecord" :key="item.id" @click="goGoodsDetailFunc(item.id)">{{item.name}}</div>
      </div>
    </div>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
@import "./index.scss";
</style>