import {
    searchRecommend
} from '@/utils/home.js';
export default {
    data() {
        return {
            showPopover: false,
            actions: [
                { text: '宝贝', color: '#666666' },
                { text: '品牌', color: '#666666' },
            ],
            searchType: '宝贝',
            searchRecord: [
                { name: '香氛沐浴露', id: '11680' },
                { name: '香氛沐浴露', id: '11679' },
                { name: '成为种草达人', id: '' },
            ],
            placeholder: '商品名称',
            searchName: ''
        }
    },
    created() {
        if (sessionStorage.getItem('searchName')) {
            this.searchName = sessionStorage.getItem('searchName');
        }
        // this.searchRecommendFunc()
    },
    methods: {
        onSelect(action, index) {
            let arr = this.actions;
            arr.forEach(item => {
                item.color = '#666666'
            })
            this.searchType = action.text;
            this.placeholder = action.text == '宝贝' ? '商品名称' : '品牌名称';
            this.actions = arr;
            this.actions[index].color = '#E62129'
        },
        searchFunc(type, recomendName) {
            this.$router.push({
                path: '/searchResult',
                query: {
                    searchName: type == 0 ? this.searchName : recomendName,
                    searchType: this.searchType == '宝贝' ? 1 : 2
                }
            })
        },
        // 获取搜索推荐
        searchRecommendFunc() {
            searchRecommend().then(res => {
                console.log('推荐', res)
                if (res.code == 1) {
                    this.searchRecord = res.result
                }
            })
        },
        // 跳转页面
        goGoodsDetailFunc(id) {
            if (id == '') {
                this.$router.push({
                    path: '/daPerson'
                })
            } else {
                this.$router.push({
                    path: '/goodsDetail',
                    query: {
                        goodsId: id
                    }
                })
            }
        }
    },
}